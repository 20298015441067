// web-app/src/components/VehicleInfo.tsx
import React from "react";
import { Typography } from "@mui/material";

const VehicleInfo : React.FC<{ user: UsersData }> = ({ user }) => (
  <>
     <Typography variant="h6">Vehicle Information</Typography>
    <Typography>Vehicle Make: {user.vehicleMake || "N/A"}</Typography>
    <Typography>Vehicle Model: {user.vehicleModel || "N/A"}</Typography>
    <Typography>Vehicle Number: {user.vehicleNumber || "N/A"}</Typography>
    <Typography>Car Type: {user.carType || "N/A"}</Typography>
    <Typography>Car Approved: {user.carApproved ? "Yes" : "No"}</Typography>
    {user.vehicleColor && <Typography>Vehicle Color: {user.vehicleColor}</Typography>}
  </>
);

export default VehicleInfo;
