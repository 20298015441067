export const FirebaseConfig = {
	"projectId": "bebafleet-79d4d",
	"appId": "1:333933720945:web:0769bdc994afb8be21b2d0",
	"databaseURL": "https://bebafleet-79d4d-default-rtdb.firebaseio.com",
	"storageBucket": "bebafleet-79d4d.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyBcr_HSP1lI8Ek0hisXNIPOtfU6HRWzx0w",
	"authDomain": "bebafleet-79d4d.firebaseapp.com",
	"messagingSenderId": "333933720945",
	"measurementId": "G-YCGJFPDVRZ"
};