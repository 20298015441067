import React, { useState, useEffect, useRef } from "react";
import { downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { makeStyles } from "@mui/styles";
import {
  Modal,
  Grid,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { colors } from "../components/Theme/WebTheme";
import CancelIcon from "@mui/icons-material/Cancel";
import AlertDialog from "../components/AlertDialog";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 20,
    height: "10%",
  },
  submit1: {
    marginLeft: 30,
  },
  submit: {
    backgroundColor: "#de7d1e",
  },
  submit5: {
    backgroundColor: "#0c5c6b",
  },
  submit3: {
    width: "100%",
    borderRadius: 3,
    marginTop: 2,
    padding: 4,
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "50%",
    maxHeight: "90vh",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  submit4: {
    backgroundColor: "red",
    width: "100%",
    borderRadius: 3,
    marginTop: 2,
    padding: 4,
  },
  imageContainer: {
    maxHeight: "60vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  responsiveImage: {
    maxWidth: "90%",
    height: "auto",
    objectFit: "contain",
  },
}));

const ImageUploadButton = ({ rowData, imageType, label, onClick }) => (
  <button
    className="text-white  bg-red-500  rounded-md text-[10px] p-2"
    onClick={() => onClick(rowData, imageType)}
  >
    {label}
  </button>
);

export default function Users() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    addUser,
    editUser,
    deleteUser,
    checkUserExists,
    fetchUsersOnce,
    updateLicenseImage,
  } = api;
  const [data, setData] = useState([]);
  const [cars, setCars] = useState({});
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const cartypes = useSelector((state) => state.cartypes);
  const auth = useSelector((state) => state.auth);
  const settings = useSelector((state) => state.settingsdata.settings);
  const dispatch = useDispatch();
  const loaded = useRef(false);
  const classes = useStyles();
  const rootRef = useRef(null);
  const [role, setRole] = useState(null);
  const [fleetAdmins, setFleetAdmins] = useState([]);
  const [fleetAdminsObj, setFleetAdminsObj] = useState();
  const [dateRange, setDateRange] = useState({
    start: "",
    end: "",
  });
  const [referrerFilter, setReferrerFilter] = useState("");
  const [documentStatusFilter, setDocumentStatusFilter] = useState("");

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (staticusers) {
      if (role === "admin" || role === "staff") {
        let arr = staticusers.filter((user) => user.usertype === "fleetadmin");
        let obj = {};
        let arr2 = [];
        for (let i = 0; i < arr.length; i++) {
          let user = arr[i];
          arr2.push({
            id: user.id,
            desc:
              user.firstName +
              " " +
              user.lastName +
              " (" +
              (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") +
              ") " +
              (settings.AllowCriticalEditsAdmin ? user.email : "Hidden"),
          });
          obj[user.id] =
            user.firstName +
            " " +
            user.lastName +
            " (" +
            (settings.AllowCriticalEditsAdmin ? user.mobile : "Hidden") +
            ") " +
            (settings.AllowCriticalEditsAdmin ? user.email : "Hidden");
        }
        setFleetAdmins(arr2);
        setFleetAdminsObj(obj);
      }
      setTimeout(() => {
        const filteredUsers = staticusers.filter(
          (user) =>
            user.usertype === "driver" &&
            ((user.fleetadmin === auth.profile.uid &&
              auth.profile.usertype === "fleetadmin") ||
              auth.profile.usertype === "admin" ||
              auth.profile.usertype === "staff")
        );

        const processedUsers = filteredUsers.map((user) => {
          const referredUsers = staticusers.filter(
            (u) => u.signupViaReferral === user.id
          );

          const today = new Date();
          today.setHours(0, 0, 0, 0);
          const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);

          const referralCounts = referredUsers.reduce(
            (counts, referredUser) => {
              const createdAt = new Date(referredUser.createdAt);

              if (createdAt >= today) {
                counts.daily++;
              }
              if (createdAt >= thisMonth) {
                counts.monthly++;
              }
              counts.total++;

              return counts;
            },
            { daily: 0, monthly: 0, total: 0 }
          );

          // use  signupViaReferral to  get the  user details
          const referredUser = staticusers.find(
            (u) => u.id === user.signupViaReferral
          )
            ? `${
                staticusers.find((u) => u.id === user.signupViaReferral)
                  .firstName
              } ${
                staticusers.find((u) => u.id === user.signupViaReferral)
                  .lastName
              } ${
                staticusers.find((u) => u.id === user.signupViaReferral).email
              }`
            : user.signupViaReferral;

          // user.signupViaReferral = referredUser

          const checkMissingDocuments = (rowData) => {
            const requiredDocuments = [
              { field: "licenseImage", name: "License Front" },
              { field: "licenseImageBack", name: "License Back" },
              // { field: 'verifyId', name: 'Verify ID' },
              { field: "psvImage", name: "PSV" },
              { field: "idImage", name: "ID" },
              { field: "logBookImage", name: "LogBook" },
              { field: "goodConduct", name: "Good Conduct" },
              { field: "driverImage", name: "Driver Image" },
              { field: "insuranceImage", name: "Insurance" },
              { field: "ntsaImage", name: "NTSA" },
            ];

            const missingDocs = requiredDocuments.filter(
              (doc) => !rowData[doc.field]
            );
            return {
              hasMissingDocs: missingDocs.length > 0,
              missingDocs: missingDocs.map((doc) => doc.name),
            };
          };

          // Check document status for each user
          const docStatus = checkMissingDocuments(user);
          const documentStatus = docStatus.hasMissingDocs
            ? {
                completed: false,
                status: "Incomplete",
                missingDocs: docStatus.missingDocs,
              }
            : {
                completed: true,
                status: "Complete",
                missingDocs: [],
              };

          return {
            ...user,
            documentStatus: documentStatus,
            dailyReferrals: referralCounts.daily,
            monthlyReferrals: referralCounts.monthly,
            totalReferrals: referralCounts.total,
            signupViaReferral: referredUser,
          };
        });

        setData(processedUsers);
      }, 1000);
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [
    staticusers,
    auth.profile.usertype,
    auth.profile.uid,
    settings.AllowCriticalEditsAdmin,
    role,
  ]);

  useEffect(() => {
    if (auth.profile && auth.profile.usertype) {
      // console.log(auth);
      setRole(auth.profile.usertype);
    }
  }, [auth.profile]);

  useEffect(() => {
    if (cartypes.cars) {
      let obj = {};
      cartypes.cars.map((car) => (obj[car.name] = car.name));
      setCars(obj);
    }
  }, [cartypes.cars]);

  const [imageType, setImageType] = useState(null);
  const [modalText, setModalText] = useState(null);
  const onClick = (rowData, text) => {
    console.log("row Data", rowData);
    console.log(text);

    setModalText(text);

    if (text === "licenseImage") {
      setImageType(text);
      setImageData(rowData.licenseImage);
    } else if (text === "licenseImageBack") {
      setImageType(text);
      setImageData(rowData.licenseImageBack);
    } else if (text === "verifyIdImage") {
      setImageType(text);
      setImageData(rowData.verifyIdImage);
    } else if (text === "Psv Image") {
      setImageType(text);
      setImageData(rowData.psvImage);
    } else if (text === "ID Image") {
      setImageType(text);
      setImageData(rowData.idImage);
    } else if (text === "LogBook Image") {
      setImageType(text);
      setImageData(rowData.logBookImage);
    } else if (text === "Driver Image") {
      setImageType(text);
      setImageData(rowData.driverImage);
    } else if (text === "Insurance Image") {
      setImageType(text);
      setImageData(rowData.insuranceImage);
    } else if (text === "NTSA Image") {
      setImageType(text);
      setImageData(rowData.ntsaImage);
    } else if (text === "Good Conduct") {
      setImageType(text);
      setImageData(rowData.goodConduct);
    }
    setProfileModal(true);
    setUserData(rowData);
  };

  const columns = [
    // {
    //   title: t("view_data"),
    //   field: "viewData",
    //   render: (rowData) => (
    //     <Link
    //       to={`/userdetails/${rowData.id}`}
    //       style={{ textDecoration: 'none', color: 'blue' }}
    //     >
    //       View Details
    //     </Link>
    //   ),
    //   cellStyle: { textAlign: "center" },
    // },
    {
      title: t("createdAt"),
      field: "createdAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) =>
        rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("first_name"),
      field: "firstName",
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("last_name"),
      field: "lastName",
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("Document Status"),
      field: "documentStatus",
      render: (rowData) => (
        <div
          style={{
            color:
              rowData.documentStatus.status === "Complete"
                ? "#4CAF50"
                : "#f44336",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>{rowData.documentStatus.status}</div>
          {rowData.documentStatus.status === "Incomplete" && (
            <div style={{ fontSize: "0.8em", color: "#666" }}>
              Missing: {rowData.documentStatus.missingDocs.join(", ")}
            </div>
          )}
        </div>
      ),
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("mobile"),
      field: "mobile",
      editable: "onAdd",
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },

    {
      title: t("email"),
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
      headerStyle: { textAlign: "center" },
    },
    {
      title: t("Location"),
      field: "address",
      editable: "onAdd",
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.address : "Hidden for Demo",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
      headerStyle: { textAlign: "center" },
    },

    {
      title: t("profile_image"),
      field: "profile_image",
      render: (rowData) =>
        rowData.profile_image ? (
          <img
            alt="Profile"
            src={rowData.profile_image}
            style={{ width: 50, borderRadius: "50%" }}
          />
        ) : null,
      editable: "never",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },

    {
      title: t("verify_id"),
      field: "verifyId",
      cellStyle: { textAlign: isRTL === "rtl" ? "center" : "left" },
    },

    {
      title: t("verifyid_image"),
      field: "verifyIdImage",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.verifyIdImage ? (
          <button
            onClick={() => {
              onClick(rowData, "verifyIdImage");
            }}
          >
            <img
              alt="License"
              src={rowData.verifyIdImage}
              style={{ width: 100 }}
            />
          </button>
        ) : null,
    },
    {
      title: t("fleetadmins"),
      field: "fleetadmin",
      hidden: role === "admin" ? false : true,
      editable: role === "admin" ? "always" : "never",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.fleetadmin ? fleetAdminsObj[rowData.fleetadmin] : null,
      editComponent: (props) => (
        <>
          {/* <MUIAutocomplete
           id="combo-box-demo"
           options={fleetAdmins}
           getOptionLabel={(option) => option.desc}
           style={{ width: 300 }}
           renderInput={(params) => (
             <TextField {...params} label="Combo box" variant="outlined" />
           )}
           onChange={(event, newValue) => {
             props.onChange(newValue.id);
           }}
         /> */}
        </>
      ),
    },
    {
      title: t("vehicle_model_name"),
      field: "vehicleMake",
      editable: "never",
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("vehicle_model_no"),
      field: "vehicleModel",
      editable: "never",
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("vehicle_reg_no"),
      field: "vehicleNumber",
      editable: "always",
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("other_info"),
      field: "other_info",
      editable: "never",
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("car_type"),
      field: "carType",
      editable: "never",
      lookup: cars,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("car_approval"),
      field: "carApproved",
      editable: "never",
      type: "boolean",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("account_approve"),
      field: "approved",
      type: "boolean",
      initialEditValue: true,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: "Documents Locked",
      field: "docsLocked",
      type: "boolean",
      initialEditValue: false,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("driver_active"),
      field: "driverActiveStatus",
      type: "boolean",
      initialEditValue: true,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("license_image_front"),
      field: "licenseImage",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.licenseImage ? (
          <button
            onClick={() => {
              onClick(rowData, "licenseImage");
            }}
          >
            <img
              alt="License"
              src={rowData.licenseImage}
              style={{ width: 100 }}
            />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="licenseImage"
            label={t("upload_license_image_front")}
            onClick={onClick}
          />
        ),
    },
    {
      title: t("license_image_back"),
      field: "licenseImageBack",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.licenseImageBack ? (
          <button
            onClick={() => {
              onClick(rowData, "licenseImageBack");
            }}
          >
            <img
              alt="License"
              src={rowData.licenseImageBack}
              style={{ width: 100 }}
            />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="licenseImageBack"
            label={t("upload_license_image_back")}
            onClick={onClick}
          />
        ),
    },
    {
      title: t("psv_image"),
      field: "Psv Image",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.psvImage ? (
          <button
            onClick={() => {
              onClick(rowData, "Psv Image");
            }}
          >
            <img alt="PsvImage" src={rowData.psvImage} style={{ width: 100 }} />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="Psv Image"
            label={t("upload_psv_image")}
            onClick={onClick}
          />
        ),
    },
    {
      title: "ID Image",
      field: "ID Image",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.idImage ? (
          <button
            onClick={() => {
              onClick(rowData, "ID Image");
            }}
          >
            <img alt="IdImage" src={rowData.idImage} style={{ width: 100 }} />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="ID Image"
            label={t("upload_id_image")}
            onClick={onClick}
          />
        ),
    },
    {
      title: t("log_book"),
      field: "Log Book Image",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.logBookImage ? (
          <button
            onClick={() => {
              onClick(rowData, "LogBook Image");
            }}
          >
            <img
              alt="License"
              src={rowData.logBookImage}
              style={{ width: 100 }}
            />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="LogBook Image"
            label={t("upload_logbook_image")}
            onClick={onClick}
          />
        ),
    },
    {
      title: "Good Conduct",
      field: "Good Conduct",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.goodConduct ? (
          <button
            onClick={() => {
              onClick(rowData, "Good Conduct");
            }}
          >
            <img
              alt="Good Conduct"
              src={rowData.goodConduct}
              style={{ width: 100 }}
            />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="Good Conduct"
            label={t("upload_good_conduct_image")}
            onClick={onClick}
          />
        ),
    },
    {
      title: t("driver_image"),
      field: "Driver Image",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.driverImage ? (
          <button
            onClick={() => {
              onClick(rowData, "Driver Image");
            }}
          >
            <img
              alt="DriversImage"
              src={rowData.driverImage}
              style={{ width: 100 }}
            />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="Driver Image"
            label={t("upload_driver_image")}
            onClick={onClick}
          />
        ),
    },
    {
      title: t("insurance_image"),
      field: "Insurance Image",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.insuranceImage ? (
          <button
            onClick={() => {
              onClick(rowData, "Insurance Image");
            }}
          >
            <img
              alt="Insurance"
              src={rowData.insuranceImage}
              style={{ width: 100 }}
            />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="Insurance Image"
            label={t("upload_insurance_image")}
            onClick={onClick}
          />
        ),
    },
    {
      title: t("ntsa_image"),
      field: "NTSA Inspection",
      cellStyle: { textAlign: "center" },
      render: (rowData) =>
        rowData.ntsaImage ? (
          <button
            onClick={() => {
              onClick(rowData, "NTSA Image");
            }}
          >
            <img
              alt="NTSA Inspection"
              src={rowData.ntsaImage}
              style={{ width: 100 }}
            />
          </button>
        ) : (
          <ImageUploadButton
            rowData={rowData}
            imageType="NTSA Image"
            label={t("upload_ntsa_image")}
            onClick={onClick}
          />
        ),
    },
    {
      title: t("wallet_balance"),
      field: "walletBalance",
      type: "numeric",
      editable: "never",
      initialEditValue: 0,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("you_rated_text"),
      render: (rowData) => <span>{rowData.rating ? rowData.rating : "0"}</span>,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("signup_via_referral"),
      field: "signupViaReferral",
      editable: "never",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("referralId"),
      field: "referralId",
      // editable: "never",
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("bankName"),
      field: "bankName",
      hidden: settings.bank_fields === false ? true : false,
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("bankCode"),
      field: "bankCode",
      hidden: settings.bank_fields === false ? true : false,
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("bankAccount"),
      field: "bankAccount",
      hidden: settings.bank_fields === false ? true : false,
      initialEditValue: "",
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("queue"),
      field: "queue",
      type: "boolean",
      initialEditValue: false,
      cellStyle: { textAlign: isRTL === "rtl" ? "right" : "center" },
    },
    {
      title: t("daily_referrals"),
      field: "dailyReferrals",
      type: "numeric",
      editable: "never",
      initialEditValue: 0,
      cellStyle: { textAlign: isRTL === "rtl" ? "center" : "center" },
      render: (rowData) => (
        <div
          style={{
            color: rowData.dailyReferrals > 0 ? "#4CAF50" : "inherit",
            fontWeight: rowData.dailyReferrals > 0 ? "bold" : "normal",
          }}
        >
          {rowData.dailyReferrals || 0}
        </div>
      ),
    },
    {
      title: t("monthly_referrals"),
      field: "monthlyReferrals",
      type: "numeric",
      editable: "never",
      initialEditValue: 0,
      cellStyle: { textAlign: isRTL === "rtl" ? "center" : "center" },
      render: (rowData) => (
        <div
          style={{
            color: rowData.monthlyReferrals > 0 ? "#2196F3" : "inherit",
            fontWeight: rowData.monthlyReferrals > 0 ? "bold" : "normal",
          }}
        >
          {rowData.monthlyReferrals || 0}
        </div>
      ),
    },
    {
      title: t("total_referrals"),
      field: "totalReferrals",
      type: "numeric",
      editable: "never",
      initialEditValue: 0,
      cellStyle: { textAlign: isRTL === "rtl" ? "center" : "center" },
      render: (rowData) => (
        <div
          style={{
            color: rowData.totalReferrals > 0 ? "#FF5722" : "inherit",
            fontWeight: rowData.totalReferrals > 0 ? "bold" : "normal",
          }}
        >
          {rowData.totalReferrals || 0}
        </div>
      ),
    },
  ];

  const [profileModal, setProfileModal] = useState(false);
  const [imageData, setImageData] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const [loading, setLoading] = useState(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const handleProfileModal = (e) => {
    setProfileModal(false);
    setSelectedImage(null);
  };

  const [userData, setUserData] = useState();

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };

  const handleSetProfileModal = (e) => {
    e.preventDefault();
    if (selectedImage) {
      setLoading(true);
      dispatch(updateLicenseImage(userData.id, selectedImage, imageType));
      setProfileModal(false);
      setSelectedImage(null);
      setTimeout(() => {
        setLoading(false);
        dispatch(fetchUsersOnce());
      }, 10000);
    } else {
      setCommonAlert({ open: true, msg: t("choose_image_first") });
    }
  };

  const [selectedRow, setSelectedRow] = useState(null);

  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [exportDate, setExportDate] = useState(null);
  const [referredUser, setReferredUser] = useState("");
  const [documentStatus, setDocumentStatus] = useState("complete");

  const hasAllDocuments = (driver) => {
    return (
      driver.licenseImage &&
      driver.licenseImageBack &&
      driver.verifyIdImage &&
      driver.psvImage &&
      driver.idImage &&
      driver.logBookImage &&
      driver.insuranceImage &&
      driver.ntsaImage &&
      driver.goodConduct &&
      driver.driverImage
    );
  };

  const handleExport = () => {
    const filteredData = data.filter((driver) => {
      const isDateMatch = exportDate
        ? moment(driver.createdAt).isSame(exportDate, "day")
        : true;
      const isReferredMatch = referredUser
        ? driver.signupViaReferral === referredUser
        : true;
      const isDocumentMatch =
        documentStatus === "complete"
          ? hasAllDocuments(driver)
          : !hasAllDocuments(driver);

      return isDateMatch && isReferredMatch && isDocumentMatch;
    });

    // Assuming convertToExcel is a function that converts data to Excel content
    // If convertToExcel is not defined, you need to define it or import it from a module
    // For demonstration, let's assume it's a simple function that converts data to CSV
    const convertToExcel = (data) => {
      let csvContent = "Name,Email,Phone,CreatedAt\n";
      data.forEach((row) => {
        csvContent += `${row.name},${row.email},${row.phone},${row.createdAt}\n`;
      });
      return csvContent;
    };

    const excelContent = convertToExcel(filteredData);
    const blob = new Blob([excelContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "drivers_export.csv");
  };

  const openExportModal = () => {
    setExportModalOpen(true);
  };

  const closeExportModal = () => {
    setExportModalOpen(false);
  };

  const handleExportUserDetails = () => {
    const userDetails = data.map((user) => ({
      Name: `${user.firstName} ${user.lastName}`,
      Email: user.email,
      Phone: user.mobile,
      // Add more fields as necessary
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      userDetails.map((e) => Object.values(e).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "user_details.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "user_details.csv"
  };

  const filterByDate = (data) => {
    if (!dateRange.start && !dateRange.end) return data;

    return data.filter((row) => {
      if (!row.createdAt) return false;
      const rowDate = moment(row.createdAt);

      if (dateRange.start && dateRange.end) {
        return rowDate.isBetween(dateRange.start, dateRange.end, "day", "[]"); // [] means inclusive
      } else if (dateRange.start) {
        return rowDate.isSameOrAfter(dateRange.start, "day");
      } else {
        return rowDate.isSameOrBefore(dateRange.end, "day");
      }
    });
  };

  const filterByReferrer = (data) => {
    if (!referrerFilter) return data;

    return data.filter((row) => {
      if (!row.signupViaReferral) return false;
      return row.signupViaReferral
        .toLowerCase()
        .includes(referrerFilter.toLowerCase());
    });
  };

  const filterByDocumentStatus = (data) => {
    if (!documentStatusFilter) return data;

    return data.filter((row) => {
      return (
        row.documentStatus.status.toString().toLowerCase() ===
        documentStatusFilter.toLowerCase()
      );
    });
  };

  const customSearch = (term, rowData) => {
    if (term === "") return true;

    // Convert search term to lowercase for case-insensitive search
    const searchTerm = term.toLowerCase();

    // Define the fields you want to search through
    const searchFields = [
      "firstName",
      "lastName",
      "mobile",
      "email",
      "vehicleNumber",
      "vehicleMake",
      "vehicleModel",
      "referralId",
      "address",
      "documentStatus",
    ];

    // Check if any field contains the search term
    return searchFields.some((field) => {
      if (!rowData[field]) return false;
      return rowData[field].toString().toLowerCase().includes(searchTerm);
    });
  };

  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <div style={{ backgroundColor: colors.LandingPage_Background }}>
      {/* <Button variant="contained" onClick={openExportModal}>
        Export Drivers
      </Button>
      <Button variant="contained" onClick={handleExportUserDetails}>
        Export User Details
      </Button> */}
      {/* 
      <Modal
        open={exportModalOpen}
        onClose={closeExportModal}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <h2>Export Drivers</h2>
          <TextField
            label="Creation Date"
            type="date"
            onChange={(e) => setExportDate(e.target.value)}
          />
          <TextField
            label="Referred User (Username/Email)"
            value={referredUser}
            onChange={(e) => setReferredUser(e.target.value)}
          />
          <Select
            value={documentStatus}
            onChange={(e) => setDocumentStatus(e.target.value)}
          >
            <MenuItem value="complete">Complete</MenuItem>
            <MenuItem value="incomplete">Incomplete</MenuItem>
          </Select>
          <Button onClick={handleExport}>Export</Button>
          <Button onClick={closeExportModal}>Cancel</Button>
        </div>
      </Modal> */}

      {/* Filters Container - Contains date range and referrer filters */}
      <div
        style={{
          margin: "10px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          height: "40px",
        }}
      >
        {/* Start Date Filter */}
        <TextField
          label={t("start_date")}
          type="date"
          value={dateRange.start}
          onChange={(e) =>
            setDateRange((prev) => ({ ...prev, start: e.target.value }))
          }
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
        />
        {/* End Date Filter */}
        <TextField
          label={t("end_date")}
          type="date"
          value={dateRange.end}
          onChange={(e) =>
            setDateRange((prev) => ({ ...prev, end: e.target.value }))
          }
          InputLabelProps={{
            shrink: true,
          }}
          size="small"
        />
        {/* Referrer Filter */}
        <TextField
          label={t("Referred By")}
          value={referrerFilter}
          onChange={(e) => setReferrerFilter(e.target.value)}
          placeholder={t("search_by_referrer")}
          style={{ minWidth: "200px" }}
          size="small"
        />

        {/* Document Status Filter */}
        <Select
          value={documentStatusFilter}
          onChange={(e) => setDocumentStatusFilter(e.target.value)}
          displayEmpty
          size="small"
          style={{ minWidth: "150px" }}
        >
          <MenuItem value="">{t("all_documents")}</MenuItem>
          <MenuItem value="complete">{t("complete")}</MenuItem>
          <MenuItem value="incomplete">{t("incomplete")}</MenuItem>
        </Select>
      </div>

      <MaterialTable
        title={t("drivers")}
        columns={columns}
        style={{
          direction: isRTL === "rtl" ? "rtl" : "ltr",
          borderRadius: "8px",
          boxShadow: "4px 4px 6px #9E9E9E",
        }}
        data={filterByReferrer(filterByDate(filterByDocumentStatus(data)))}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          exportCsv: (columns, data) => {
            let hArray = [];
            const headerRow = columns.map((col) => {
              if (typeof col.title === "object") {
                return col.title.props.text;
              }
              hArray.push(col.field);
              return col.title;
            });
            const dataRows = data.map(({ tableData, ...row }) => {
              row.createdAt = moment(row.createdAt).format('MM/DD/YYYY');
              row.fleetadmin = row.fleetadmin
                ? fleetAdminsObj[row.fleetadmin]
                : "";

              row.documentStatus = row.documentStatus.completed
                ? row.documentStatus.status
                : row.documentStatus.missingDocs.join("-");

              let dArr = [];
              for (let i = 0; i < hArray.length; i++) {
                dArr.push(row[hArray[i]]);
              }
              return Object.values(dArr);
            });
            const { exportDelimiter } = ",";
            const delimiter = exportDelimiter ? exportDelimiter : ",";
            const csvContent = [headerRow, ...dataRows]
              .map((e) => e.join(delimiter))
              .join("\n");
            const csvFileName = "download.csv";
            downloadCsv(csvContent, csvFileName);
          },
          exportButton: {
            csv: settings.AllowCriticalEditsAdmin,
            pdf: false,
          },
          maxColumnSort: "all_columns",
          rowStyle: (rowData) => ({
            backgroundColor:
              selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
          }),
          editable: {
            backgroundColor: colors.Header_Text,
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: 'Lucida Console", "Courier New", monospace',
          },
          headerStyle: {
            position: "sticky",
            top: "0px",
            backgroundColor: colors.Header_Text_back,
            color: "#fff",
            fontSize: "0.8em",
            fontWeight: "bold",
            fontFamily: 'Lucida Console", "Courier New", monospace',
            zIndex: 1,
            padding: "8px", // Reduced padding for more compact header
            height: "40px", // Fixed height for header
            minHeight: "40px", // Ensures consistent header height
          },
          pageSizeOptions: [5, 10, 20, 50, 100],

          actionsColumnIndex: auth.profile.usertype === "admin" ? 0 : -100,

          searchText: undefined,
          customFilterAndSearch: (term, rowData) => customSearch(term, rowData),
          maxBodyHeight: "700px", // Increased to show more records
        }}
        localization={{
          body: {
            addTooltip: t("add"),
            deleteTooltip: t("delete"),
            editTooltip: t("edit"),
            emptyDataSourceMessage: t("blank_message"),
            editRow: {
              deleteText: t("delete_message"),
              cancelTooltip: t("cancel"),
              saveTooltip: t("save"),
            },
          },
          toolbar: {
            searchPlaceholder: t("search"),
            exportTitle: t("export"),
          },
          header: {
            actions: t("actions"),
          },
          pagination: {
            labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
            firstTooltip: t("first_page_tooltip"),
            previousTooltip: t("previous_page_tooltip"),
            nextTooltip: t("next_page_tooltip"),
            lastTooltip: t("last_page_tooltip"),
          },
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                checkUserExists(newData).then((res) => {
                  if (res.users && res.users.length > 0) {
                    alert(t("user_exists"));
                    reject();
                  } else if (!(newData && newData.firstName)) {
                    alert(t("proper_input_name"));
                    reject();
                  } else if (res.error) {
                    alert(t("email_or_mobile_issue"));
                    reject();
                  } else if (
                    settings &&
                    settings.license_image_required &&
                    (newData.licenseImage === "" || !newData.licenseImage)
                  ) {
                    alert(t("proper_input_licenseimage"));
                    reject();
                  } else if (
                    settings &&
                    settings.license_image_required &&
                    (newData.licenseImageBack === "" ||
                      !newData.licenseImageBack)
                  ) {
                    alert(t("proper_input_licenseimage"));
                    reject();
                  } else if (
                    settings &&
                    settings.imageIdApproval &&
                    (newData.verifyIdImage === "" || !newData.verifyIdImage)
                  ) {
                    alert(t("upload_id_details"));
                    reject();
                  } else if (
                    settings &&
                    settings.imageIdApproval &&
                    !newData.verifyId
                  ) {
                    alert(t("upload_id_details"));
                    reject();
                  } else {
                    newData["usertype"] = "driver";
                    newData["createdAt"] = new Date().getTime();
                    const c = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                    const reference = [...Array(5)]
                      .map((_) => c[~~(Math.random() * c.length)])
                      .join("");
                    newData["referralId"] = reference;
                    let role = auth.profile.usertype;
                    if (role === "fleetadmin") {
                      newData["fleetadmin"] = auth.profile.uid;
                    }
                    dispatch(addUser(newData));
                    dispatch(fetchUsersOnce());
                    resolve();
                  }
                });
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (!(newData && newData.firstName)) {
                  alert(t("proper_input_name"));
                  reject();
                } else if (
                  settings &&
                  settings.license_image_required &&
                  !(newData && newData.licenseImage)
                ) {
                  alert(t("proper_input_licenseimage"));
                  reject();
                } else if (
                  settings &&
                  settings.imageIdApproval &&
                  (newData.verifyIdImage === "" || !newData.verifyIdImage)
                ) {
                  alert(t("upload_id_details"));
                  reject();
                } else if (
                  settings &&
                  settings.imageIdApproval &&
                  !newData.verifyId
                ) {
                  alert(t("upload_id_details"));
                  reject();
                } else {
                  resolve();
                  if (newData !== oldData) {
                    delete newData.tableData;
                    dispatch(editUser(oldData.id, newData));
                    dispatch(fetchUsersOnce());
                  }
                }
              }, 600);
            }),
          onRowDelete: (oldData) =>
            settings.AllowCriticalEditsAdmin
              ? new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    dispatch(deleteUser(oldData.id));
                    dispatch(fetchUsersOnce());
                  }, 600);
                })
              : new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    alert(t("demo_mode"));
                  }, 600);
                }),
        }}
      />
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={profileModal}
        onClose={handleProfileModal}
        className={classes.modal}
        container={() => rootRef.current}
      >
        <Grid
          container
          spacing={1}
          className={classes.paper}
          style={{
            direction: isRTL === "rtl" ? "rtl" : "ltr",
            maxHeight: "90vh",
            overflow: "auto",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography component="h1" variant="h6">
              {modalText ? modalText : t("license_image")}
              <input
                type="file"
                style={{ marginLeft: 10 }}
                name={t("image")}
                onChange={(event) => {
                  setSelectedImage(event.target.files[0]);
                }}
              />
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            {selectedImage && !loading ? (
              <Tooltip title={t("cancel")}>
                <CancelIcon
                  onClick={() => setSelectedImage(null)}
                  style={{
                    fontSize: 30,
                    backgroundColor: "red",
                    borderRadius: 50,
                    color: "white",
                  }}
                />
              </Tooltip>
            ) : null}
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.imageContainer}
          >
            {selectedImage ? (
              <img
                alt="not found"
                className={classes.responsiveImage}
                src={URL.createObjectURL(selectedImage)}
                style={{ marginTop: 15, marginBottom: 20 }}
              />
            ) : (
              <img
                alt="licimage"
                className={classes.responsiveImage}
                src={imageData}
                style={{ marginTop: 10 }}
              />
            )}
            <br />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
          >
            {loading ? (
              <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                style={{ minHeight: "5vh" }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                style={{ textAlign: isRTL === "rtl" ? "right" : "left" }}
              >
                <Button
                  onClick={handleProfileModal}
                  variant="contained"
                  // color="danger"
                >
                  {t("cancel")}
                </Button>
                <Button
                  onClick={handleSetProfileModal}
                  variant="contained"
                  // color="secondary"
                  style={{ marginLeft: 10 }}
                >
                  {t("save")}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Modal>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
}
