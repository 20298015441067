   // web-app/src/components/UserInfo.tsx
   import React from 'react';
   import { Typography } from '@mui/material';

   const UserInfo : React.FC<{ user: UsersData }> = ({ user }) => (
     <>
       <Typography variant="h5">{user.firstName} {user.lastName}</Typography>
       <Typography>User Type: {user.usertype || 'N/A'}</Typography>
       <Typography>Email: {user.email || 'N/A'}</Typography>
       <Typography>Phone: {user.mobile || 'N/A'}</Typography>
     </>
   );

   export default UserInfo;