   // web-app/src/components/BankInfo.tsx
   import React from 'react';
   import { Typography } from '@mui/material';

   const BankInfo: React.FC<{ user: UsersData }> = ({ user }) => (
     <>
       <Typography variant="h6">Bank Information</Typography>
       <Typography>Bank Account: {user.bankAccount || 'N/A'}</Typography>
       <Typography>Bank Code: {user.bankCode || 'N/A'}</Typography>
     </>
   );

   export default BankInfo;