import * as turf from "@turf/turf";
import Nairobi from "./SupportedCounties/Nairobi.json" 
import Mombasa from "./SupportedCounties/Mombasa.json"
import Kiambu from "./SupportedCounties/Kiambu.json" 

export const  COUNTIES_KEYS={
  NAIROBI: 'Nairobi',
  MOMBASA: 'Mombasa',
  KIAMBU: 'Kiambu'
}

// List of county data
const counties = [
  { name: COUNTIES_KEYS.NAIROBI, data: Nairobi },
  { name: COUNTIES_KEYS.MOMBASA, data: Mombasa },
  { name: COUNTIES_KEYS.KIAMBU, data: Kiambu },
];

// console.log(counties);

// Utility function to check if the given latitude and longitude are inside any county
export const isInsideCounty = (latitude, longitude) => {
  try{
  const point = turf.point([longitude, latitude]);

  for (const county of counties) {
    const { name, data } = county;

    // Extract the MultiPolygon coordinates from the JSON file
    const multiPolygonCoordinates = data.geometries.coordinates;

    // Create a MultiPolygon turf object
    const multiPolygon = turf.multiPolygon(multiPolygonCoordinates);

    // Check if the point is within the MultiPolygon
    if (turf.booleanPointInPolygon(point, multiPolygon)) {
      return name;
    }
  }
  }catch(error){
    console.log(error);
  }

  // If no county match is found
  return 'Outside Mombasa, Kiambu, or Nairobi';
};
 const checkLocation = (latitude, longitude) => {
  const county = isInsideCounty(latitude, longitude);
  console.log(`The location is in: ${county}`);
  return county
};

// checkLocation(-1.2150257413057193, 36.675061536771516); //GITARU

// checkLocation(-1.0327950287221852, 37.074871016518216); //THIKA

// checkLocation(-1.373150422906083, 36.850978927162195); // NAIROBI NATIONAL PART


// checkLocation(-4.009372657541059, 39.69152584336718); // MOMBASA BAKARANI STAGE 




