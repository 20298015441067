   // web-app/src/components/DocumentPreview.tsx
   import React from 'react';
   import { Typography } from '@mui/material';


   const DocumentPreview: React.FC<{ user: UsersData }> = ({ user }) => (
     <>
       <Typography variant="h6">User Documents</Typography>
       <Typography>ID Image: <a href={user.idImage} target="_blank" rel="noopener noreferrer">View</a></Typography>
       <Typography>License Image: <a href={user.licenseImage} target="_blank" rel="noopener noreferrer">View</a></Typography>
       <Typography>License Image (Back): <a href={user.licenseImageBack} target="_blank" rel="noopener noreferrer">View</a></Typography>
     </>
   );

   export default DocumentPreview;