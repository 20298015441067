import {
    UPDATE_TRIP_PICKUP,
    UPDATE_TRIP_DROP,
    UPDATE_TRIP_CAR,
    UPDATE_SELECTED_POINT_TYPE,
    CLEAR_TRIP_POINTS,
    UPDATE_ACTIVE_MAP_MODAL
} from "../store/types";

export const updateTripPickup = (pickupAddress: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: UPDATE_TRIP_PICKUP,
        payload: pickupAddress
    });
};

export const updateTripDrop = (dropAddress: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: UPDATE_TRIP_DROP,
        payload: dropAddress
    });
};

export const updateTripCar = (selectedCar: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: UPDATE_TRIP_CAR,
        payload: selectedCar
    });
};

export const updateSelPointType = (selection: any) => (dispatch: (arg0: { type: string; payload: any; }) => void) => {
    dispatch({
        type: UPDATE_SELECTED_POINT_TYPE,
        payload: selection
    });
};

export const clearTripPoints = () => (dispatch: (arg0: { type: string; payload: null; }) => void) => {
    dispatch({
        type: CLEAR_TRIP_POINTS,
        payload: null
    });
};


export const updateActiveMapModal=(activeModal:"search" | "bookings" | null) =>(dispatch: (arg0: { type: string; payload: "search" | "bookings" | null; }) => void) =>{
    dispatch(
        {
            type: UPDATE_ACTIVE_MAP_MODAL,
            payload: activeModal
        }
    )
}


