import React, { useState, useEffect, useRef } from "react";
import { downloadCsv } from "../common/sharedFunctions";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from "common";
import { useTranslation } from "react-i18next";
import moment from "moment/min/moment-with-locales";
import { colors } from "../components/Theme/WebTheme";

export default function Users() {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const { addUser, editUser, deleteUser, checkUserExists, fetchUsersOnce } =
    api;
  const [data, setData] = useState([]);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const loaded = useRef(false);

  const auth = useSelector((state) => state.auth);
  const bookinglistdata = useSelector((state) => state.bookinglistdata);

  const [corporatePrices, setCorporatePrices] = useState({});

  useEffect(() => {
    if (bookinglistdata.bookings) {
      let corporatePricesObj = {};
      for (let i = 0; i < bookinglistdata.bookings.length; i++) {
        if (
          (bookinglistdata.bookings[i].status === "PAID" ||
            bookinglistdata.bookings[i].status === "COMPLETE") &&
          bookinglistdata.bookings[i].corporateadmin
        ) {
          let today = new Date();
          let monthlyTotal = 0;
          let yearlyTotal = 0;
          let todayTotal = 0;
          const { tripdate, trip_cost, corporateadmin } =
            bookinglistdata.bookings[i];
          let tDate = new Date(tripdate);

          if (trip_cost && parseFloat(trip_cost) > 0 && corporateadmin) {
            if (
              tDate.getDate() === today.getDate() &&
              tDate.getMonth() === today.getMonth() &&
              tDate.getFullYear() === today.getFullYear()
            ) {
              todayTotal = parseFloat(trip_cost);
            }
            if (
              tDate.getMonth() === today.getMonth() &&
              tDate.getFullYear() === today.getFullYear()
            ) {
              monthlyTotal = parseFloat(trip_cost);
            }
            yearlyTotal = parseFloat(trip_cost);
          }

          // here we append the coporate price to global state for  corporate  prices
          let corporateId = corporateadmin;
          if (!corporatePricesObj[corporateId]) {
            corporatePricesObj[corporateId] = {
              monthlyTotal,
              yearlyTotal,
              todayTotal,
            };
          } else {
            corporatePricesObj[corporateId].monthlyTotal += monthlyTotal;
            corporatePricesObj[corporateId].yearlyTotal += yearlyTotal;
            corporatePricesObj[corporateId].todayTotal += todayTotal;
          }
        }
      }
      setCorporatePrices(corporatePricesObj);
    }
  }, [
    bookinglistdata.bookings,
    settings,
    auth.profile.uid,
    auth.profile.usertype,
  ]);

  useEffect(() => {
    dispatch(fetchUsersOnce());
  }, [dispatch, fetchUsersOnce]);

  useEffect(() => {
    if (staticusers) {
      setData(
        staticusers.filter((user) => {
          return user.usertype === "corporateadmin";
        })
      );
    } else {
      setData([]);
    }
    loaded.current = true;
  }, [staticusers]);

  const columns = [
    {
      title: t("createdAt"),
      field: "createdAt",
      editable: "never",
      defaultSort: "desc",
      render: (rowData) =>
        rowData.createdAt ? moment(rowData.createdAt).format("lll") : null,
      exportTransformer: (rowData) =>
        new Date(rowData.createdAt).toLocaleDateString() +
        " " +
        new Date(rowData.createdAt).toLocaleTimeString(),
    },
    {
      title: t("first_name"),
      field: "firstName",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 50 : 15 },
    },
    {
      title: t("last_name"),
      field: "lastName",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    {
      title: t("mobile"),
      field: "mobile",
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.mobile : "Hidden for Demo",
    },
    {
      title: t("email"),
      field: "email",
      editable: "onAdd",
      render: (rowData) =>
        settings.AllowCriticalEditsAdmin ? rowData.email : "Hidden for Demo",
      headerStyle: { textAlign: "center" },
    },

    // today price
    {
      title: "Today Total",
      field: "todayTotal",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },
    // monthly price
    {
      title: "Monthly Total",
      field: "monthlyTotal",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    }, // today price
    {
      title: "Yearly Today",
      field: "yearlyTotal",
      initialEditValue: "",
      cellStyle: { paddingLeft: isRTL === "rtl" ? 30 : 15 },
    },

    {
      title: t("account_approve"),
      field: "approved",
      type: "boolean",
      initialEditValue: true,
      cellStyle: { textAlign: "center" },
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  function objIsEmpty(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (objIsEmpty(corporatePrices) === false && data.length > 0) {
      // console.log("users", data);
      // console.log("prices", corporatePrices);
      let newUserData = data;
      //  let loop through corporatePrices and get  key value  pairs
      for (let [key, value] of Object.entries(corporatePrices)) {
        // lets loop through user data  : data
        for (let i = 0; i < newUserData.length; i++) {
          if (newUserData[i].id === key) {
            newUserData[i].todayTotal = value.todayTotal;
            newUserData[i].monthlyTotal = value.monthlyTotal;
            newUserData[i].yearlyTotal = value.yearlyTotal;
          } else {
            if (newUserData[i].todayTotal === undefined)
              newUserData[i].todayTotal = 0;
            if (newUserData[i].monthlyTotal === undefined)
              newUserData[i].monthlyTotal = 0;
            if (newUserData[i].yearlyTotal === undefined)
              newUserData[i].yearlyTotal = 0;
          }
        }
      }
      // console.log("newUserData", newUserData);
      setData(newUserData);
    }
  }, [corporatePrices, data]);

  return !loaded.current ? (
    <CircularLoading />
  ) : (
    <MaterialTable
      //   title={t("corporateadmins")}
      title={"Corporate Expenditure"}
      columns={columns}
      style={{
        direction: isRTL === "rtl" ? "rtl" : "ltr",
        borderRadius: "8px",
        boxShadow: "4px 4px 6px #9E9E9E",
      }}
      data={data}
      onRowClick={(evt, selectedRow) =>
        setSelectedRow(selectedRow.tableData.id)
      }
      options={{
        exportCsv: (columns, data) => {
          let hArray = [];
          const headerRow = columns.map((col) => {
            if (typeof col.title === "object") {
              return col.title.props.text;
            }
            hArray.push(col.field);
            return col.title;
          });
          const dataRows = data.map(({ tableData, ...row }) => {
            row.createdAt =
              new Date(row.createdAt).toLocaleDateString() +
              " " +
              new Date(row.createdAt).toLocaleTimeString();
            let dArr = [];
            for (let i = 0; i < hArray.length; i++) {
              dArr.push(row[hArray[i]]);
            }
            return Object.values(dArr);
          });
          const { exportDelimiter } = ",";
          const delimiter = exportDelimiter ? exportDelimiter : ",";
          const csvContent = [headerRow, ...dataRows]
            .map((e) => e.join(delimiter))
            .join("\n");
          const csvFileName = "download.csv";
          downloadCsv(csvContent, csvFileName);
        },
        exportButton: {
          csv: settings.AllowCriticalEditsAdmin,
          pdf: false,
        },
        maxColumnSort: "all_columns",
        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
        editable: {
          backgroundColor: colors.Header_Text,
          fontSize: "0.8em",
          fontWeight: "bold ",
          fontFamily: 'Lucida Console", "Courier New", monospace',
        },
        headerStyle: {
          position: "sticky",
          top: "0px",
          backgroundColor: colors.Header_Text_back,
          color: "#fff",
          fontSize: "0.8em",
          fontWeight: "bold ",
          fontFamily: 'Lucida Console", "Courier New", monospace',
        },
      }}
      localization={{
        body: {
          addTooltip: t("add"),
          deleteTooltip: t("delete"),
          editTooltip: t("edit"),
          emptyDataSourceMessage: t("blank_message"),
          editRow: {
            deleteText: t("delete_message"),
            cancelTooltip: t("cancel"),
            saveTooltip: t("save"),
          },
        },
        toolbar: {
          searchPlaceholder: t("search"),
          exportTitle: t("export"),
        },
        header: {
          actions: t("actions"),
        },
        pagination: {
          labelDisplayedRows: "{from}-{to} " + t("of") + " {count}",
          firstTooltip: t("first_page_tooltip"),
          previousTooltip: t("previous_page_tooltip"),
          nextTooltip: t("next_page_tooltip"),
          lastTooltip: t("last_page_tooltip"),
        },
      }}
    />
  );
}
