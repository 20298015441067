import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./views/LoginPage.js";
import AuthLoading from "./views/AuthLoading";
import { Provider } from "react-redux";
import ProtectedRoute from "./views/ProtectedRoute";
import MyProfile from "./views/MyProfile";
import BookingHistory from "./views/BookingHistory";
import Dashboard from "./views/Dashboard";
import CorporateDashboard from "./views/CorporateDashboard";
import CarTypes from "./views/CarTypes";
import AddBookings from "./views/AddBookings";
import Promos from "./views/Promos";
import Users from "./views/Users";
import Notifications from "./views/Notifications";
import Settings from "./views/Settings.js";
import CancellationReasons from "./views/CancellationReasons";
import AddMoney from "./views/AddMoney";
import Withdraws from "./views/Withdraws";
import AllReports from "./views/AllReports";
import { FirebaseProvider, store } from "common";
import { FirebaseConfig } from "./config/FirebaseConfig";
import { GoogleMapApiConfig } from "./config/GoogleMapApiConfig";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import UserWallet from "./views/UserWallet";
import CarsList from "./views/CarsList";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useJsApiLoader } from "@react-google-maps/api";
import CorporateExpenditure from "./views/CorporateExpenditure";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { webClientId } from "config/ClientIds.js";
import { HelmetProvider } from "react-helmet-async";
import UserDetails from "./views/UserDetails";

// import tailwind.css
import "./assets/css/global.css";

import PoliceTracker from "views/TrackingPage.js";

const libraries = ["geometry", "drawing", "places"];

i18n.use(initReactI18next).init({
  resources: {},
  fallbackLng: "en",
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  const [mode, setMode] = useState("light");

  const theme = createTheme({
    palette: {
      mode: mode,
    },
  });

  // eslint-disable-next-line no-unused-vars
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  useJsApiLoader({
    id: "google-map",
    googleMapsApiKey: GoogleMapApiConfig,
    libraries,
  });

  return (
    <HelmetProvider>
      <Provider store={store}>
        <FirebaseProvider config={FirebaseConfig}>
          <GoogleOAuthProvider clientId={webClientId}>
            <ThemeProvider theme={theme}>
              {/* <button onClick={toggleTheme}>Toggle Theme</button> */}
              <AuthLoading>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute permit={"admin,fleetadmin,staff"}>
                          <Dashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/tracking"
                      element={
                        <ProtectedRoute permit={"admin,police-tracker"}>
                          <PoliceTracker />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/corporate"
                      element={
                        <ProtectedRoute permit={"corporateadmin"}>
                          <CorporateDashboard />
                        </ProtectedRoute>
                      }
                    />
                    {/* corporate dashboard info */}
                    <Route
                      path="/corporateinfo"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <CorporateExpenditure />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/bookings"
                      element={
                        <ProtectedRoute
                          permit={
                            "customer,admin,driver,fleetadmin,corporateadmin"
                          }
                        >
                          <BookingHistory />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/profile"
                      element={
                        <ProtectedRoute
                          permit={
                            "customer,admin,driver,fleetadmin,corporateadmin,staff"
                          }
                        >
                          <MyProfile />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/cartypes"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <CarTypes />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/cars"
                      element={
                        <ProtectedRoute permit={"admin,fleetadmin,driver,staff"}>
                          <CarsList />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/cancelreasons"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <CancellationReasons />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/addbookings"
                      element={
                        <ProtectedRoute permit={"admin,fleetadmin,customer"}>
                          <AddBookings />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/promos"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <Promos />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <ProtectedRoute
                          permit={"admin,fleetadmin,corporateadmin,staff"}
                        >
                          <Users />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/notifications"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <Notifications />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/addtowallet"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <AddMoney />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/userwallet"
                      element={
                        <ProtectedRoute permit={"customer,driver"}>
                          <UserWallet />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/withdraws"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <Withdraws />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/allreports"
                      element={
                        <ProtectedRoute permit={"admin,fleetadmin"}>
                          <AllReports />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <ProtectedRoute permit={"admin"}>
                          <Settings />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/" element={<LoginPage />} />
                    <Route path="/login" element={<LoginPage />} />

                    <Route
                      path="/userdetails/:userId"
                      element={
                        <ProtectedRoute permit={"admin,staff"}>
                          <UserDetails />
                        </ProtectedRoute>
                      }
                    />

                    {/* <Route path="/contact-us" element={<ContactUs />} />
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/term-condition" element={<TermCondition />} />
                    <Route path="/faq" element={<FAQPage />} /> */}
                  </Routes>
                </BrowserRouter>
              </AuthLoading>
            </ThemeProvider>
          </GoogleOAuthProvider>
        </FirebaseProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
