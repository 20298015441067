// export default "U2FsdGVkX1/hgMx1YNiKS47bLHJJh4+nsy4VA+kmNpuz/lnKJl2Pxgl///xagbfccfFdLtPANcy/ipuDX5jRlg==";

import config from "../../../functions/config.json";
import base64 from "react-native-base64";

const createAccessKey = () => {
  let accessKey = base64.encode(
    `${config.purchase_code}-${config.firebaseProjectId}`
  );
  return accessKey;
};

const createSecretAccessKey = () => {
  let accessKey = base64.encode(
    `${config.secret_key}-${config.firebaseProjectId}`
  );
  return accessKey;
};

export default createAccessKey();

export { createAccessKey, createSecretAccessKey };
