import {
  FETCH_ESTIMATE,
  FETCH_ESTIMATE_SUCCESS,
  FETCH_ESTIMATE_FAILED,
  CLEAR_ESTIMATE,
} from "../store/types";
import Polyline from "@mapbox/polyline";
import { firebase } from "../config/configureFirebase";
import { FareCalculator } from "../other/FareCalculator";
import { onValue } from "firebase/database";

export const getEstimate = (bookingData) => async (dispatch) => {

  // console.log("==========> BOOKING DATA PICKUP IN GET ESTIMATE ", bookingData);
  const { settingsRef } = firebase;
  dispatch({
    type: FETCH_ESTIMATE,
    payload: bookingData,
  });

  let res = bookingData.routeDetails;
  let auth = bookingData.auth;
  // let bookinglistdata = bookingData.bookinglistdata;

  // console.log.log("================> BOOKING LIST DATA IN GET ESTIMATE ", bookinglistdata);

  // console.log(`========>Auth in get estimate ${JSON.stringify(auth)} `);
  // console.log(`========>BOOKING  DATA  in get estimate ${JSON.stringify(bookingData)} `);

  if (res) {
    let points = Polyline.decode(res.polylinePoints);

    let waypoints = points.map((point) => {
      return {
        latitude: point[0],
        longitude: point[1],
      };
    });

    onValue(
      settingsRef,
      (settingdata) => {
        let settings = settingdata.val();
        let distance = settings.convert_to_mile
          ? res.distance_in_km / 1.609344
          : res.distance_in_km;

        // try {
          let { totalCost, grandTotal, convenience_fees } = FareCalculator(
            distance,
            res.time_in_secs,
            bookingData.carDetails,
            bookingData.instructionData,
            settings.decimal,
            auth,
            bookingData
          );
        // } catch (err) {
        //   console.log("===================>FARE CALCULATION ERROR ", err);
        // }
        // if (auth && auth.profile && auth.profile.corporateadmin) {
        //   console.log(
        //     `======> COMPUTED  PRICE FOR CORPORATE STAFF IS : total cost: ${totalCost} grandTotal: ${grandTotal}`
        //   );
        // }

        // if(!bookingData.pickup.lat || !bookingData.pickup.lng){
        //   return;
        // }

        dispatch({
          type: FETCH_ESTIMATE_SUCCESS,
          payload: {
            pickup: bookingData.pickup,
            drop: bookingData.drop,
            carDetails: bookingData.carDetails,
            instructionData: bookingData.instructionData,
            estimateDistance: parseFloat(distance).toFixed(settings.decimal),
            fareCost: totalCost
              ? parseFloat(totalCost).toFixed(settings.decimal)
              : 0,
            estimateFare: grandTotal
              ? parseFloat(grandTotal).toFixed(settings.decimal)
              : 0,
            estimateTime: res.time_in_secs,
            convenience_fees: convenience_fees
              ? parseFloat(convenience_fees).toFixed(settings.decimal)
              : 0,
            waypoints: waypoints,
          },
        });
      },
      { onlyOnce: true }
    );
  } else {
    console.log("======================>No Route Found");
    
    dispatch({
      type: FETCH_ESTIMATE_FAILED,
      payload: "No Route Found",
    });
  }
};

export const clearEstimate = () => (dispatch) => {
  dispatch({
    type: CLEAR_ESTIMATE,
    payload: null,
  });
};
