import React, { createContext } from "react";
import { initializeApp, getApp, getApps } from "firebase/app";
import {
  getDatabase,
  ref,
  query,
  orderByChild,
  equalTo,
} from "firebase/database";
import {
  initializeAuth,
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  RecaptchaVerifier,
  unlink,
  updatePhoneNumber,
  linkWithPhoneNumber,
  browserLocalPersistence,
  browserPopupRedirectResolver,
} from "firebase/auth";
import { getStorage, ref as stRef } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getReactNativePersistence } from "firebase/auth/react-native";

const FirebaseContext = createContext(null);

interface FirebaseContextType {
  app: ReturnType<typeof initializeApp> | any | null;
  database: ReturnType<typeof getDatabase> |any | null;
  auth: ReturnType<typeof getAuth> | any | null;
  storage: ReturnType<typeof getStorage> | any | null;
  firestore: ReturnType<typeof getFirestore> |any;
  config?: any;
  authRef?: ReturnType<typeof getAuth> | any;
  googleProvider?: GoogleAuthProvider;
  appleProvider?: OAuthProvider;
  phoneProvider?: PhoneAuthProvider;
  RecaptchaVerifier?: typeof RecaptchaVerifier;
  signInWithPhoneNumber?: typeof signInWithPhoneNumber;
  updatePhoneNumber?: typeof updatePhoneNumber;
  unlink?: typeof unlink;
  googleCredential?: (idToken: string, accessToken: string) => any;
  linkWithPhoneNumber?: typeof linkWithPhoneNumber;
  mobileAuthCredential?: (verificationId: string, code: string) => any;
  usersRef?: ReturnType<typeof ref>;
  userByIdRef?: (id: string) => ReturnType<typeof ref>;
  driversRef?: ReturnType<typeof query>;
  bookingRef?: ReturnType<typeof ref>;
  cancelreasonRef?: ReturnType<typeof ref>;
  settingsRef?: ReturnType<typeof ref>;
  smtpRef?: ReturnType<typeof ref>;
  carTypesRef?: ReturnType<typeof ref>;
  carTypesEditRef?: (id: string) => ReturnType<typeof ref>;
  carDocImage?: (id: string) => ReturnType<typeof stRef>;
  promoRef?: ReturnType<typeof ref>;
  promoEditRef?: (id: string) => ReturnType<typeof ref>;
  notifyRef?: ReturnType<typeof ref>;
  notifyEditRef?: (id: string) => ReturnType<typeof ref>;
  addressRef?: (uid: string, id: string) => ReturnType<typeof ref>;
  addressEditRef?: (uid: string) => ReturnType<typeof ref>;
  singleUserRef?: (uid: string) => ReturnType<typeof ref>;
  profileImageRef?: (uid: string) => ReturnType<typeof stRef>;
  verifyIdImageRef?: (uid: string) => ReturnType<typeof stRef>;

  callsRef?: (bookingId: string) => ReturnType<typeof ref>;
  userSearchHistoryRef?: (uid: string) => ReturnType<typeof ref>;

  bookingImageRef?: (bookingId: string, imageType: string) => ReturnType<typeof stRef>;

  
}


let firebase: FirebaseContextType = {
  app: null,
  database: null,
  auth: null,
  storage: null,
  firestore: null,
};

const createFullStructure = (app:any, db:any, auth:any, storage:any, config:any ,firestore:any) => {
  return {
    app: app,
    config: config,
    database: db,
    auth: auth,
    storage: storage,
    firestore:firestore,
    authRef: getAuth,
    googleProvider: new GoogleAuthProvider(),
    appleProvider: new OAuthProvider("apple.com"),
    phoneProvider: new PhoneAuthProvider(auth),
    RecaptchaVerifier: RecaptchaVerifier,
    signInWithPhoneNumber: signInWithPhoneNumber,
    updatePhoneNumber: updatePhoneNumber,
    unlink: unlink,
    googleCredential: (idToken:any, accessToken:any) =>
      GoogleAuthProvider.credential(idToken, accessToken),
    linkWithPhoneNumber: linkWithPhoneNumber,
    mobileAuthCredential: (verificationId:any, code:any) =>
      PhoneAuthProvider.credential(verificationId, code),
    usersRef: ref(db, "users"),
    userByIdRef:(id:any) => ref(db, "users/" + id),
    bookingRef: ref(db, "bookings"),
    cancelreasonRef: ref(db, "cancel_reason"),
    settingsRef: ref(db, "settings"),
    smtpRef: ref(db, "smtpdata"),
    carTypesRef: ref(db, "cartypes"),
    carTypesEditRef: (id:any) => ref(db, "cartypes/" + id),
    carDocImage: (id:any) => stRef(storage, `cartypes/${id}`),
    promoRef: ref(db, "promos"),
    promoEditRef: (id:any) => ref(db, "promos/" + id),
    notifyRef: ref(db, "notifications/"),
    notifyEditRef: (id:any) => ref(db, "notifications/" + id),
    addressRef: (uid:any, id:any) => ref(db, "savedAddresses/" + uid + "/" + id),
    addressEditRef: (uid:any) => ref(db, "savedAddresses/" + uid),
    singleUserRef: (uid:any) => ref(db, "users/" + uid),
    profileImageRef: (uid:any) => stRef(storage, `users/${uid}/profileImage`),
    verifyIdImageRef: (uid:any) => stRef(storage, `users/${uid}/verifyIdImage`),
    
    userSearchHistoryRef: (uid:any) => ref(db, `users/${uid}/searchHistory`),

    bookingImageRef: (bookingId:any, imageType:any) =>
      stRef(storage, `bookings/${bookingId}/${imageType}`),
    driversRef: query(
      ref(db, "users"),
      orderByChild("usertype"),
      equalTo("driver")
    ),

    // driver documents
    idImageRef: (uid:any) => stRef(storage, `users/${uid}/idImage`),
    driverDocsRef: (uid:any) => stRef(storage, `users/${uid}/license`),
    driverDocsRefBack: (uid:any) => stRef(storage, `users/${uid}/licenseBack`),
    psvImageRef: (uid:any) => stRef(storage, `users/${uid}/psvImage`),
    logBookImageRef: (uid:any) => stRef(storage, `users/${uid}/logBookImage`),
    driverImageRef: (uid:any) => stRef(storage, `users/${uid}/driverImage`),
    insuranceImageRef: (uid:any) => stRef(storage, `users/${uid}/insuranceImage`),
    ntsaImageRef: (uid:any) => stRef(storage, `users/${uid}/ntsaImage`),
    goodConductRef: (uid:any) => stRef(storage, `users/${uid}/goodConduct`),

    // end driver docs

    singleBookingRef: (bookingKey:any) => ref(db, "bookings/" + bookingKey),
    requestedDriversRef: (bookingKey:any) =>
      ref(db, "bookings/" + bookingKey + "/requestedDrivers"),
    referralIdRef: (referralId:any) =>
      query(ref(db, "users"), orderByChild("referralId"), equalTo(referralId)),
    trackingRef: (bookingId:any) => ref(db, "tracking/" + bookingId),
    tasksRef: () =>
      query(ref(db, "bookings"), orderByChild("status"), equalTo("NEW")),
    singleTaskRef: (uid:any, bookingId:any) =>
      ref(db, "bookings/" + bookingId + "/requestedDrivers/" + uid),
    bookingListRef: (uid:any, role:any) =>
      role == "customer"
        ? query(ref(db, "bookings"), orderByChild("customer"), equalTo(uid))
        : role == "driver"
        ? query(ref(db, "bookings"), orderByChild("driver"), equalTo(uid))
        : role == "fleetadmin"
        ? query(ref(db, "bookings"), orderByChild("fleetadmin"), equalTo(uid))
        : //if corporate admin
        role == "corporateadmin"
        ? query(
            ref(db, "bookings"),
            orderByChild("corporateadmin"),
            equalTo(uid)
          )
        : // if  admin
          ref(db, "bookings"),
    chatRef: (bookingId:any) => ref(db, "chats/" + bookingId + "/messages"),
    
    // calls ref
    callsRef: (bookingId:any) => ref(db, "calls/" + bookingId),

    withdrawRef: ref(db, "withdraws/"),
    languagesRef: ref(db, "languages"),
    languagesEditRef: (id:any) => ref(db, "languages/" + id),
    walletHistoryRef: (uid:any) => ref(db, "walletHistory/" + uid),
    userNotificationsRef: (uid:any) => ref(db, "userNotifications/" + uid),
    userRatingsRef: (uid:any) => ref(db, "userRatings/" + uid),
    carsRef: (uid:any, role:any) =>
      role == "driver"
        ? query(ref(db, "cars"), orderByChild("driver"), equalTo(uid))
        : role == "fleetadmin"
        ? query(ref(db, "cars"), orderByChild("fleetadmin"), equalTo(uid))
        : ref(db, "cars"),
    carAddRef: ref(db, "cars"),
    carEditRef: (id:any) => ref(db, "cars/" + id),
    carImage: (id:any) => stRef(storage, `cars/${id}`),
    allLocationsRef: ref(db, "locations"),
    userLocationRef: (uid:any) => ref(db, "locations/" + uid),
  };
};

const FirebaseProvider = ({ config, children, AsyncStorage }:any) => {
  let app:any, auth:any, database:any, storage:any ,firestore:any;

  if (!getApps().length) {
    try {
      app = initializeApp(config);
      if (typeof document !== "undefined") {
        auth = initializeAuth(app, {
          persistence: browserLocalPersistence,
          popupRedirectResolver: browserPopupRedirectResolver,
        });
      } else {
        auth = initializeAuth(app, {
          persistence: getReactNativePersistence(AsyncStorage),
        });
      }
      database = getDatabase(app);
      storage = getStorage(app);
      firestore = getFirestore(app);
    } catch (error) {
      console.log("Error initializing app: " + error);
    }
  } else {
    app = getApp();
    auth = getAuth(app);
    database = getDatabase(app);
    storage = getStorage(app);
    firestore = getFirestore(app);

  }

  firebase = createFullStructure(app, database, auth, storage, config,firestore);

  return (
    <FirebaseContext.Provider value={firebase as any}>
      {children}
    </FirebaseContext.Provider>
  );
};

export { firebase, FirebaseProvider, FirebaseContext };
