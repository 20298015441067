import {
    SAVE_CALL_DATA,
    FETCH_CALL_DATA,
    FETCH_CALL_DATA_SUCCESS,
    FETCH_CALL_DATA_FAILED,
} from "../store/types";

const INITIAL_STATE = {
    callData: [],
    loading: false,
    error: null,
};

export const callReducer = (state = INITIAL_STATE, action:any) => {
    switch (action.type) {
        case FETCH_CALL_DATA:
            return { ...state, loading: true };
        case FETCH_CALL_DATA_SUCCESS:
            return { ...state, loading: false, callData: action.payload };
        case FETCH_CALL_DATA_FAILED:
            return { ...state, loading: false, error: action.payload };
        case SAVE_CALL_DATA:
            return { ...state, callData: [...state.callData, action.payload] };
        default:
            return state;
    }
}; 